<h1 id="title" class="title">Forgot Password</h1>
<p class="sub-title">Enter your email address and we’ll send a link to reset your password</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert
  *ngIf="showMessages.success && messages?.length && !submitted"
  outline="success"
  role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>

<form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">
  <div class="form-control-group">
    <label class="label" for="input-email">Enter your recovery email address:</label>
    <input
      nbInput
      [(ngModel)]="user.email"
      #email="ngModel"
      id="input-email"
      name="email"
      pattern=".+@.+\..+"
      placeholder="Email address"
      autofocus
      fullWidth
      fieldSize="large"
      [status]="email.dirty ? (email.invalid ? 'danger' : 'success') : 'basic'"
      [required]="getConfigValue('forms.validation.email.required')"
      [attr.aria-invalid]="email.invalid && email.touched ? true : null" />
    <ng-container *ngIf="email.invalid && email.touched">
      <p class="caption status-danger" *ngIf="email.errors?.required">Email is required!</p>
      <p class="caption status-danger" *ngIf="email.errors?.pattern">
        Email should be the real one!
      </p>
    </ng-container>
  </div>

  <button
    nbButton
    fullWidth
    status="primary"
    size="large"
    [disabled]="submitted || !requestPassForm.valid"
    [class.btn-pulse]="submitted">
    Request password
  </button>
</form>
