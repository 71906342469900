import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ProvidersService {
  constructor(private http: HttpClient) {}

  async getProviders() {
    return this.http.get("providers/get_all");
  }

  async getAllProviders(page, filters) {
    return this.http
      .post("providers/get_all" + (page ? "?page=" + page : ""), { filters })
      .toPromise();
  }

  async getProvider(id) {
    return this.http.get(`providers/get_provider/${id}`);
  }

  async change_status(data) {
    return this.http.post(`providers/change_status/${data.id}`, { status: data.status });
  }

  async deleteProvider(id) {
    return this.http.post(`providers/delete/${id}`, {});
  }

  async updateProvider(id, values) {
    return this.http.post(`providers/update_provider/${id}`, { values });
  }

  async createProvider(data) {
    return this.http.post(`providers/create`, { data: data });
  }
}
