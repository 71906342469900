<div [formGroup]="form">
  <nb-select
    [id]="field.id || field.key"
    [formControlName]="field.key + '_game'"
    fullWidth
    placeholder="{{ 'game' | translate }}"
    [class]="classes"
    (selectedChange)="onSelectionChange($event)">
    <nb-option [value]="'all'">{{ "all" | translate }}</nb-option>
    <nb-option-group
      *ngFor="let category of categories"
      [title]="category.key === 'no_category' ? (category.key | translate) : category.key">
      <nb-option *ngFor="let game of category.games" [value]="game.game_key">
        {{ game.name }}
      </nb-option>
    </nb-option-group>
  </nb-select>
  <nb-select
    *ngIf="tables[this.form.value[field.key + '_game']]"
    [id]="(field.id || field.key) + '_table'"
    [formControlName]="field.key + '_table'"
    fullWidth
    placeholder="{{ 'table' | translate }}"
    (ngModelChange)="onChangeTable($event)"
    [class]="classes">
    <nb-option [value]="''">{{ "all" | translate }}</nb-option>
    <nb-option
      *ngFor="let table of tables[this.form.value[field.key + '_game']]"
      [value]="table.id">
      {{ table.name }}
    </nb-option>
  </nb-select>
  <input
    *ngIf="field.isTransaction && visibleIdGames.includes(form.value[field.key + '_game'])"
    type="text"
    [id]="(field.id || field.key) + '_visible_id'"
    [formControlName]="'visible_id'"
    fullWidth
    nbInput
    placeholder="{{ 'visible_id' | translate }}" />
</div>
