import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ngx-big-card",
  templateUrl: "./big-card.component.html",
  styleUrls: ["./big-card.component.scss"],
})
export class BigCardComponent implements OnInit {
  @Input() centerContent: boolean = false;
  @Input() blurred: boolean = false;
  @Input() biggerBlur: boolean = false;
  @Input() customHeight: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
