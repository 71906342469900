import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectionMenuComponent } from "./selection-menu/selection-menu.component";
import {
  NbAccordionModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbMenuModule,
  NbSelectModule,
  NbSpinnerModule,
  NbTagModule,
} from "@nebular/theme";
import { BigCardComponent } from "./big-card/big-card.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FilterComponent } from "./filter/filter.component";
import { TranslateModule } from "@ngx-translate/core";
import { PlayerSearchComponent } from "./filter/input/player-search/player-search.component";
import { SelectProviderComponent } from "./filter/input/select-provider/select-provider.component";
import { SelectOperatorComponent } from "./filter/input/select-operator/select-operator.component";
import { DateInputComponent } from "./filter/input/date-input/date-input.component";
import { TableDataComponent } from "./table-data/table-data.component";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { FieldPipe } from "./table-data/pipes/field.pipe";
import { SelectGameTableComponent } from "./filter/input/select-game-table/select-game-table.component";
import { RouterModule } from "@angular/router";
import { CreateComponent } from "./create/create.component";
import { SelectRoleComponent } from "./filter/input/select-role/select-role.component";
import { SelectGameComponent } from "./filter/input/select-game/select-game.component";
import { StackedChartModule } from "./stacked-chart/stacked-chart.module";
import { DashboardTabsComponent } from "./filter/input/dashboard/dashboard-tabs";
import { RequestPasswordComponent } from "./auth/request-password/request-password/request-password.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password/reset-password.component";
import { ComponentDirectivesModule } from "./directives/component-directives.module";

@NgModule({
  declarations: [
    SelectionMenuComponent,
    BigCardComponent,
    FilterComponent,
    PlayerSearchComponent,
    SelectProviderComponent,
    SelectOperatorComponent,
    SelectRoleComponent,
    DateInputComponent,
    TableDataComponent,
    FieldPipe,
    SelectGameTableComponent,
    CreateComponent,
    SelectGameComponent,
    DashboardTabsComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    NbMenuModule,
    ReactiveFormsModule,
    FormsModule,
    NbCardModule,
    NbAccordionModule,
    NbSelectModule,
    TranslateModule,
    NbButtonModule,
    NbSpinnerModule,
    NbAutocompleteModule,
    NbInputModule,
    NbDatepickerModule,
    NbAlertModule,
    PaginationModule,
    NbIconModule,
    RouterModule,
    StackedChartModule,
    ComponentDirectivesModule,
    NbTagModule,
  ],
  exports: [
    SelectionMenuComponent,
    BigCardComponent,
    FilterComponent,
    TableDataComponent,
    SelectGameTableComponent,
    SelectOperatorComponent,
    CreateComponent,
    SelectGameComponent,
    DashboardTabsComponent,
  ],
})
export class ComponentsModule {}
