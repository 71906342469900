<div [formGroup]="form">
  <input
    #autoInput
    [id]="field.key"
    [formControl]="form.controls[field.key]"
    nbInput
    type="text"
    autocomplete="off"
    fullWidth
    [placeholder]="field.placeholder | translate"
    (keyup)="searchUsername()"
    [nbAutocomplete]="auto" />
  <nb-autocomplete (selectedChange)="playerSelected($event)" #auto>
    <nb-option *ngFor="let player of searchedPlayers$" [value]="player.username">
      {{ player.username }}
    </nb-option>
  </nb-autocomplete>
</div>
