import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { NbAuthOAuth2JWTToken, NbAuthService } from "@nebular/auth";
import { Router } from "@angular/router";
import { NbToastrService } from "@nebular/theme";
import { RoleGuard } from "../guards/role-guard";

export interface Credentials {
  name?: string;
  email?: string;
  remember?: boolean;
}

const credentialsKey = "credentials";

@Injectable()
export class UserService {
  public token = "";
  public _credentials;
  public user;

  constructor(
    private http: HttpClient,
    private authService: NbAuthService,
    private toasterService: NbToastrService,
    private router: Router,
    private roleGuard: RoleGuard
  ) {
    const savedCredentials = localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this.setCredentials(JSON.parse(savedCredentials));
    }
  }

  getUser() {
    return this.authService.getToken().subscribe((token: NbAuthOAuth2JWTToken) => {
      localStorage.setItem("token", token.getValue());
      return this.http
        .get("api/user", {
          headers: { Authorization: "Bearer " + token.getValue() },
        })
        .pipe(
          map((data) => {
            const newCredentials: Credentials = { ...this.credentials, ...data };
            this.user = newCredentials;
            this.setCredentials(newCredentials);
            this.roleGuard.permissions = this.user.perms;
            this.roleGuard.role = this.user.role;

            this.roleGuard.createMenuItems();
            return newCredentials;
          })
        )
        .subscribe(
          (data) => {},
          (error) => {
            this.toasterService.show(error.error["data"] || error.message, "Error!", {
              status: "danger",
            });
            this.router.navigate(["/auth/logout"], { replaceUrl: true });
          }
        );
    });
  }

  get credentials(): any {
    return this._credentials;
  }

  private setCredentials(credentials?: Credentials) {
    this._credentials = credentials;
    if (credentials) {
      localStorage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      localStorage.removeItem(credentialsKey);
    }
  }
}
