import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OperatorFilter } from "../../models/operator.model";

@Injectable({
  providedIn: "root",
})
export class OperatorsService {
  constructor(private http: HttpClient) {}

  getOperators() {
    return this.http.get<OperatorFilter[]>("operators/get_all");
  }
  async getProvidersOperators(id) {
    return this.http.get(`operators/get_providers_operators/${id}`);
  }
  async getAllOperators(page, filters) {
    return this.http.post("operators/get_all" + (page ? "?page=" + page : ""), { filters });
  }

  async getOperator(id) {
    return this.http.get(`operators/get_operator/${id}`);
  }

  async change_status(data) {
    return this.http.post(`operators/change_status/${data.id}`, { status: data.status });
  }

  async deleteOperator(id) {
    return this.http.post(`operators/delete/${id}`, {});
  }

  async updateOperator(id, values) {
    return this.http.post(`operators/update_operator/${id}`, { values });
  }

  async createOperator(data) {
    return this.http.post(`operators/create`, { data: data });
  }

  getWhitelists(page, id) {
    return this.http.get(`operators/get_whitelists/${id}` + (page ? `?page=` + page : ``));
  }

  addWhitelist(data) {
    return this.http.post(`operators/create_whitelist`, { data: data });
  }

  deleteWhitelist(id) {
    return this.http.post(`operators/delete_whitelist/${id}`, {});
  }
}
