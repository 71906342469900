import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "abs",
})
export class AbsPipe implements PipeTransform {
  transform(number: number) {
    return Math.abs(number);
  }
}
