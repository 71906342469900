import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ExportType } from "../dtos/enums/ExportType";

@Injectable({
  providedIn: "root",
})
export class ExportsService {
  constructor(private http: HttpClient) {}

  create(type: ExportType, filters) {
    return this.http.post("exports", { type, filters }).toPromise();
  }

  get(params?) {
    return this.http.get<any>("exports", { params }).toPromise();
  }

  download(id: number) {
    return this.http.get(`exports/download/${id}`, { responseType: "blob" }).toPromise();
  }

  delete(id: number) {
    return this.http.delete(`exports/${id}`).toPromise();
  }
}
