<div [formGroup]="form">
  <nb-select
    [id]="field.key"
    [formControl]="form.controls[field.key]"
    fullWidth
    placeholder="{{ field.placeholder | translate }}"
    (selectedChange)="operatorSelected($event)">
    <nb-option [value]="''">{{ "all" | translate }}</nb-option>
    <nb-option-group *ngFor="let provider of filteredProviders" [title]="provider.name">
      <nb-option [value]="operator[bindValue]" *ngFor="let operator of provider.operators">
        {{ operator.name }}
      </nb-option>
    </nb-option-group>
  </nb-select>
</div>
