import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";

import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let secureReq: HttpRequest<any>;
    if (req.url.indexOf("i18n") !== -1 || req.headers.has("Skip-Interceptor")) {
      return next.handle(req);
    }

    secureReq = req.clone({
      url: environment.serverUrl + req.url,
      setHeaders: {
        Authorization: "Bearer " + localStorage.token,
      },
    });

    return next.handle(secureReq);
  }
}
