<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><span>Backoffice</span></a>
  </div>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <div class="header-buttons">
    <nb-form-field class="lang-wrapper">
      <img class="lang-image" nbPrefix src="../../../../assets/flags/{{translate.currentLang}}.png" alt="crm">
      <nb-select id="language" [selected]="translate.currentLang" (selectedChange)="useLanguage($event)" fullWidth>
        <nb-option [value]="language.key" *ngFor="let language of languages">
          <img class="lang-image" src="../../../../assets/flags/{{language.key}}.png" alt="crm">
          {{language?.name}}
        </nb-option>
      </nb-select>
    </nb-form-field>
  </div>
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [name]="userService._credentials?.username">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
