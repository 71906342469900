import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FilterFieldDto } from "../../dto/filter-field.dto";
import { GamesService } from "../../../../pages/games/games.service";

@Component({
  selector: "ngx-select-game[form][field]",
  templateUrl: "./select-game.component.html",
})
export class SelectGameComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() field: FilterFieldDto;
  @Output() gameLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  games;
  constructor(private gameService: GamesService) {}

  async ngOnInit() {
    await this.getGames();
    this.gameLoaded.emit(true);
  }

  async getGames() {
    this.games = await this.gameService.getGames();
  }
}
