import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { PlayersService } from "../../../../pages/players/players.service";
import { FormGroup } from "@angular/forms";
import { FilterFieldDto } from "../../dto/filter-field.dto";

@Component({
  selector: "ngx-player-search[form][playerSearchKey][field]",
  templateUrl: "./player-search.component.html",
})
export class PlayerSearchComponent {
  constructor(private playerService: PlayersService) {}

  @Input() form: FormGroup;
  @Input() field: FilterFieldDto;
  @Input() playerSearchKey: string;

  @ViewChild("autoInput") input;
  timeout: any;
  searchedPlayers$;

  searchUsername() {
    if (!this.playerSearchKey) return;

    clearTimeout(this.timeout);
    if (!this.form.controls[this.playerSearchKey].value) {
      this.form.controls["player_id"].setValue(null);
      this.searchedPlayers$ = [];
      return;
    }

    this.timeout = setTimeout(async () => {
      await this.getPlayersForFilter();
    }, 200);
  }

  async getPlayersForFilter() {
    const filters = {
      username: this.form.controls[this.playerSearchKey].value,
      operator_id: this.form.controls["operator_id"]?.value || "",
      provider_id: this.form.controls["provider_id"]?.value || "",
      player_type: this.form.controls["player_type"]?.value || "",
    };
    await this.playerService.getPlayersForFilter(filters).subscribe((data) => {
      this.searchedPlayers$ = data;
    });
  }

  playerSelected($event) {
    const playerSelected = this.searchedPlayers$?.find((player) => player.username === $event);
    if (!playerSelected) {
      return;
    }
    this.form.controls["player_id"].setValue(playerSelected.id);
  }
}
