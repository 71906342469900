import * as moment from "moment";

export class DateHelper {
  static DATE_FORMAT = "YYYY/MM/DD HH:mm:ss";
  static getDaysAgo(days) {
    const date = new Date();
    const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
    const day = last.getDate();
    const month = last.getMonth() + 1;
    const year = last.getFullYear();
    return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
  }

  static getCustomDate(date) {
    if (!date) {
      return;
    }
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
  }

  static getCustomDateTime(date) {
    if (!date) {
      return;
    }
    return date;
  }

  static getStartOfTheMonth(months) {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth() - months, 1);
    const day = firstDay.getDate();
    const month = firstDay.getMonth() + 1;
    const year = firstDay.getFullYear();
    return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
  }

  static getStartOfTheYear(years) {
    const now = new Date();
    const firstDay = new Date(now.getFullYear() - years, 1, 1);
    const day = firstDay.getDate();
    const month = firstDay.getMonth();
    const year = firstDay.getFullYear();
    return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
  }

  static getStartOfTheWeek(i) {
    const now = new Date();
    now.setDate(now.getDate() - i * 7);
    const dayy = now.getDay(),
      diff = now.getDate() - dayy + (dayy === 0 ? -6 : 1); // adjust when day is sunday
    const firstDay = new Date(now.setDate(diff));
    const day = firstDay.getDate();
    const month = firstDay.getMonth() + 1;
    const year = firstDay.getFullYear();
    return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
  }

  static convertToTimestamp(date, dateType) {
    let exactDate = moment(date);
    if (dateType === "end") {
      exactDate = exactDate.endOf("day");
    }
    return exactDate.format(DateHelper.DATE_FORMAT);
  }
  static convertToTimestampCustom(date) {
    if (!date) {
      return moment(Date.now()).format(DateHelper.DATE_FORMAT);
    }

    return moment(date).format(DateHelper.DATE_FORMAT);
  }
  static convertToTimestampCustomDate(date): Date {
    if (!date) {
      return moment(Date.now()).toDate();
    }

    return moment(date).toDate();
  }
}
