<div class="chart-wrapper white" [ngStyle]="{ height: resolvedHeight }">
  <ngx-charts-bar-vertical-stacked
    [scheme]="colorScheme"
    [results]="data"
    [legend]="legend"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [legendPosition]="legendPosition">
  </ngx-charts-bar-vertical-stacked>
</div>
