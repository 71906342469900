import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CreateDto } from "./dto/create.dto";

@Component({
  selector: "ngx-create[createData] [submitCallback]",
  templateUrl: "./create.component.html",
})
export class CreateComponent implements OnInit {
  @Input() createData: CreateDto;
  @Output() submitCallback = new EventEmitter<any>();
  form: FormGroup;
  types: any = {};
  filteredProviders;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    let config: { [p: string]: any } = {};
    for (let column of this.createData.columns) {
      for (let field of column.fields) {
        this.types[field.key] = field.type;
        config[field.key] = [
          { value: field.selectedValue ?? "", disabled: field.disabled },
          Validators.required,
        ];
      }
    }
    this.form = this.fb.group(config);
  }

  changeProvider(providers: any[]) {
    this.filteredProviders = providers;
  }

  cancel() {
    this.form.reset();
  }

  submit() {
    this.submitCallback.emit(this.form.value);
    this.form.reset();
  }
}
