<div [formGroup]="form">
  <nb-select
    [id]="field.key"
    [formControl]="form.controls[field.key]"
    fullWidth
    placeholder="{{ field.placeholder | translate }}">
    <nb-option [value]="''">{{ "all" | translate }}</nb-option>
    <nb-option *ngFor="let game of games" [value]="game.id">
      {{ game.name }}
    </nb-option>
  </nb-select>
</div>
