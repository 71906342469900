import { Pipe, PipeTransform } from "@angular/core";
import { TableFieldDto } from "../dto/table-field.dto";
import { DatePipe, DecimalPipe } from "@angular/common";

@Pipe({
  name: "fieldPipe",
})
export class FieldPipe implements PipeTransform {
  transform(value: TableFieldDto): string {
    let output = value.value;
    if (value.isNumber) {
      output = new DecimalPipe("1.0-2").transform(output);
    } else if (value.isDate) {
      output = new DatePipe("en").transform(output, "dd/MM/YY HH:mm:ss");
    }
    return output;
  }
}
