<div [formGroup]="form" *ngIf="userRole !== 'operator'">
  <nb-select
    [id]="field.key"
    [formControl]="form.controls[field.key]"
    fullWidth
    placeholder="{{ field.placeholder | translate }}"
    (selectedChange)="providerSelected($event)">
    <nb-option [value]="''">{{ "all" | translate }}</nb-option>
    <nb-option *ngFor="let provider of providers" [value]="provider.id">
      {{ provider.name }}
    </nb-option>
  </nb-select>
</div>
