<form id="form-dateTabs" class="form_dateTabs" [formGroup]="form">
  <div class="col-lg-12">
    <nb-card>
      <nb-card-body>
        <div class="col-lg-12 header_date_holder">
          <div *ngFor="let date of dates" class="header_date_div">
            <span
              (click)="changeTab(date.key)"
              [ngClass]="{ tab_active: activeTab === date.key }"
              class="header_date_tab"
              translate
              >{{ date.key }}</span
            >
          </div>
        </div>
      </nb-card-body>
    </nb-card>
    <nb-card>
      <nb-card-body>
        <div class="row">
          <div class="col-md-3" *ngFor="let column of filterData.columns">
            <ng-container *ngFor="let filter of column.filters">
              <div *ngIf="filter.type === 'provider'" class="form-group">
                <label class="label">{{ "provider" | translate }}</label>
                <ngx-select-provider
                  [form]="form"
                  [field]="filter"
                  (providerLoaded)="loadKey(filter.key)"
                  (changeProvider)="changeProvider($event)">
                </ngx-select-provider>
              </div>
              <div *ngIf="filter.type === 'operator'" class="form-group">
                <label class="label">{{ "operator" | translate }}</label>
                <ngx-select-operator
                  [form]="form"
                  [field]="filter"
                  [providers]="filteredProviders"
                  (operatorLoaded)="loadKey(filter.key)">
                </ngx-select-operator>
              </div>
            </ng-container>
          </div>

          <div *ngIf="showCustom" class="form-group col-md-3">
            <label class="label">{{ "start_date" | translate }}</label>
            <input
              id="date_start_custom"
              formControlName="date_start_custom"
              nbInput
              fullWidth
              [autocomplete]="'off'"
              class="mt-1"
              placeholder="{{ 'start_date' | translate }}"
              [nbDatepicker]="date_start_custom" />
            <nb-date-timepicker #date_start_custom format="dd-MM-YYYY, HH:mm"></nb-date-timepicker>
          </div>
          <div *ngIf="showCustom" class="form-group col-md-3">
            <label class="label">{{ "end_date" | translate }}</label>
            <input
              id="date_end_custom"
              formControlName="date_end_custom"
              nbInput
              fullWidth
              [autocomplete]="'off'"
              class="mt-1"
              placeholder="{{ 'end_date' | translate }}"
              [nbDatepicker]="date_end_custom" />
            <nb-date-timepicker #date_end_custom format="dd-MM-YYYY, HH:mm"></nb-date-timepicker>
          </div>
        </div>
        <div class="col-md-4 d-flex align-items-center ml-auto mt-2">
          <button
            *ngFor="let b of filterData.buttons"
            type="button"
            class="mx-1"
            nbButton
            fullWidth
            [status]="b.status"
            (click)="b.onClick === 'clear' ? clear() : submit()"
            [disabled]="b.disabled"
            [nbSpinner]="b.disabled">
            {{ b.label | translate }}
          </button>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</form>
