import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FilterFieldDto } from "../../dto/filter-field.dto";
import { GamesService } from "../../../../pages/games/games.service";
import { ActivatedRoute } from "@angular/router";
import { Category } from "../../../../models/Category";
import { tables } from "./tables";
import { Game, GameFilter } from "../../../../models/Game";
@Component({
  selector: "ngx-select-game-table[form][field]",
  templateUrl: "./select-game-table.component.html",
})
export class SelectGameTableComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() field: FilterFieldDto;
  @Input() classes: string;
  @Input() hasAll: boolean = true;
  @Output() changeGame: EventEmitter<string> = new EventEmitter<string>();
  selectedGame = "all";

  @Input() limitCategories: Category[];
  @Input() allGames: GameFilter[];
  categories: Category[] = [];
  games: any = [];
  visibleIdGames: string[] = ["falcon_raptor", "falcon_hilo"];
  readonly tables = tables;
  table = "";
  game_key;

  onSelectionChange(selectedValue: any) {
    this.form.controls[this.field.key + "_table"].setValue("");
    this.selectedGame = selectedValue;
    if (selectedValue) {
      this.sendGame();
    }
    this.form.get("visible_id")?.setValue("");
  }

  onChangeTable(event) {
    this.table = event;
    this.sendGame();
  }

  sendGame() {
    this.form.controls[this.field.key].setValue(
      `${this.selectedGame}${this.table ? `:${this.table}` : ""}`
    );

    if (!this.changeGame) return;
    this.changeGame.emit(this.form.controls[this.field.key].value);
  }

  constructor(private gamesService: GamesService, private route: ActivatedRoute) {}

  async ngOnInit() {
    this.game_key = this.form.controls[this.field.key].value;
    const queryParams = this.route.snapshot.queryParams;
    if (Object.keys(queryParams).length !== 0) {
      this.onSelectionChange(queryParams[this.field.key]);
    }
    if (this.hasAll)
      this.categories.splice(0, 0, {
        id: -1,
        key: "All",
        games: [],
      });

    await this.getCategories();
    await this.getAllGames();
    this.addGamesToNoCategory();
  }

  async getCategories() {
    this.categories = this.limitCategories || (await this.gamesService.getGameByCategories());
  }

  async getAllGames() {
    this.allGames = this.allGames || (await this.gamesService.getGames());
  }

  addGamesToNoCategory() {
    const noCategory = {
      key: "no_category",
      games: [],
    };

    this.allGames?.forEach((game) => {
      let gameExists = false;

      for (const category of this.categories) {
        if (category.games.some((categoryGame) => categoryGame.id === game.id)) {
          gameExists = true;
          break;
        }
      }

      if (!gameExists) {
        noCategory.games.push(game);
      }
    });

    if (noCategory.games.length) {
      this.categories.push(noCategory);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedGame = this.form.controls[this.field.key + "_game"].value;
    this.table = this.form.controls[this.field.key + "_table"].value;
    this.form.controls[this.field.key].setValue(
      `${this.selectedGame}${this.table ? `:${this.table}` : ""}`
    );
  }
}
