<div *ngIf="roleNames?.length" [formGroup]="form">
  <nb-select
    [id]="field.key"
    [formControl]="form.controls[field.key]"
    fullWidth
    placeholder="{{ field.placeholder | translate }}">
    <nb-option [value]="''">{{ "all" | translate }}</nb-option>
    <nb-option *ngFor="let role of roleNames" [value]="role">
      {{ role }}
    </nb-option>
  </nb-select>
</div>
