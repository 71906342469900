import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpClient,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  excludedURLS = [
    `${environment.serverUrl}users/reset-password`,
    `${environment.serverUrl}auth/login`,
    `${environment.serverUrl}validate`,
  ];

  constructor(private router: Router, private http: HttpClient) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status >= 300 && !this.excludedURLS.includes(error.url)) {
          this.http
            .get<{ type: string }>("validate")
            .pipe(
              catchError((validateError) => {
                this.router.navigateByUrl("/auth/logout");

                return throwError(validateError);
              })
            )
            .subscribe((response) => {
              if (response.type === "success") return;

              this.router.navigateByUrl("/auth/logout");
            });
        }
        return throwError(error);
      })
    );
  }
}
