import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FilterDto } from "../../dto/filter.dto";
import { FormGroup } from "@angular/forms";
import { FilterFieldDto } from "../../dto/filter-field.dto";
import { ActivatedRoute } from "@angular/router";
import { DateHelper } from "../../../../services/date";
import { Subscription } from "rxjs";

@Component({
  selector: "ngx-dashboard-tabs[form] [filterData] [filteredProviders]",
  templateUrl: "./dashboard-tabs.html",
  styleUrls: ["./dashboard-tabs.css"],
})
export class DashboardTabsComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() field: FilterFieldDto;
  @Input() filterData: FilterDto;
  @Input() filteredProviders;
  @Output() loadKeyEvent = new EventEmitter<string>();
  @Output() changeProviderEvent = new EventEmitter<any>();
  @Output() clearEvent = new EventEmitter<void>();
  @Output() submitEvent = new EventEmitter<void>();
  @Output() activeTabChanged: EventEmitter<string> = new EventEmitter<string>();

  dates = [
    { key: "today" },
    { key: "yesterday" },
    { key: "last_seven_days" },
    { key: "last_thirty_days" },
    { key: "this_month" },
    { key: "last_month" },
    { key: "custom" },
  ];
  activeTab = "today";
  showCustom = false;
  customStartSub: Subscription;
  customEndSub: Subscription;
  today = DateHelper.getDaysAgo(0);
  yesterday = DateHelper.getDaysAgo(1);
  startKey = "date_start_value";
  endKey = "date_end_value";
  constructor(private _route: ActivatedRoute) {}
  ngOnInit(): void {
    const queryParameters = this._route.snapshot.queryParams;

    if (queryParameters.activeTab) {
      this.activeTab = queryParameters.activeTab;
    }
    if (queryParameters.activeTab == "custom") {
      this.showCustom = true;

      this.customStartSub = this.form.get("date_start_custom").valueChanges.subscribe((value) => {
        if (!this.showCustom) return;
        this.changeTab("custom", "custom_start", value);
      });

      this.customEndSub = this.form.get("date_end_custom").valueChanges.subscribe((value) => {
        if (!this.showCustom) return;
        this.changeTab("custom", "custom_end", value);
      });
    }
    if (!queryParameters.activeTab) {
      this.initialRequest();
    }
  }

  initialRequest() {
    const newFormValues = {
      date: "today",
      [this.startKey]: this.convertStartDate(this.today),
      [this.endKey]: this.convertEndDate(this.today),
    };
    this.form.patchValue(newFormValues, { emitEvent: false });
    this.activeTabChanged.emit(this.activeTab);
    this.submit();
  }
  changeTab(key: string, event?, value?: string) {
    this.showCustom = false;

    let newFormValues = {};
    newFormValues["date"] = key;

    switch (key) {
      case "today":
        newFormValues[this.startKey] = this.convertStartDate(this.today);
        newFormValues[this.endKey] = this.convertEndDate(this.today);
        break;
      case "yesterday":
        newFormValues[this.startKey] = this.convertStartDate(this.yesterday);
        newFormValues[this.endKey] = this.convertEndDate(this.yesterday);
        break;
      case "last_seven_days":
        const seven_days = DateHelper.getDaysAgo(6);
        newFormValues[this.startKey] = this.convertStartDate(seven_days);
        newFormValues[this.endKey] = this.convertEndDate(this.today);
        break;
      case "last_thirty_days":
        const thirty_days = DateHelper.getDaysAgo(30);
        newFormValues[this.startKey] = this.convertStartDate(thirty_days);
        newFormValues[this.endKey] = this.convertEndDate(this.today);
        break;
      case "this_month":
        const this_month = DateHelper.getStartOfTheMonth(0);
        newFormValues[this.startKey] = this.convertStartDate(this_month);
        newFormValues[this.endKey] = this.convertEndDate(this.today);
        break;
      case "last_month":
        const last_month_start = DateHelper.getStartOfTheMonth(1);
        const last_month_end = DateHelper.getStartOfTheMonth(0);
        newFormValues[this.startKey] = this.convertStartDate(last_month_start);
        newFormValues[this.endKey] = this.convertStartDate(last_month_end);
        break;
      case "custom":
        this.showCustom = true;
        if (event == "custom_start") {
          if (value !== undefined) {
            const custom_date_start = DateHelper.getCustomDateTime(value);
            newFormValues[this.startKey] = this.convertDateCustom(custom_date_start);
          } else {
            newFormValues[this.startKey] = undefined;
          }
          this.showCustom = true;
        } else if (event == "custom_end") {
          if (value) {
            const custom_date_end = DateHelper.getCustomDateTime(value);
            newFormValues[this.endKey] = this.convertDateCustom(custom_date_end);
          } else {
            newFormValues[this.endKey] = undefined;
          }
          this.showCustom = true;
        }
    }
    this.form.patchValue(newFormValues, { emitEvent: false });
    this.activeTab = key;
    this.activeTabChanged.emit(this.activeTab);
    if (key !== "custom") {
      this.submit();
    }
  }
  private convertStartDate(date) {
    return DateHelper.convertToTimestamp(date, "start");
  }
  private convertEndDate(date) {
    return DateHelper.convertToTimestamp(date, "end");
  }
  private convertDateCustom(date: string) {
    return DateHelper.convertToTimestampCustom(date);
  }
  loadKey(key: string) {
    this.loadKeyEvent.emit(key);
  }
  changeProvider(event: any) {
    this.changeProviderEvent.emit(event);
  }

  clear() {
    this.clearEvent.emit();
  }

  submit() {
    this.submitEvent.emit();
  }
  ngOnDestroy(): void {
    if (this.customStartSub) {
      this.customStartSub.unsubscribe();
    }
    if (this.customEndSub) {
      this.customEndSub.unsubscribe();
    }
  }
}
