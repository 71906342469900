import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TableDto } from "./dto/table.dto";
import { ExportsService } from "../../services/exports.service";
import { NbDialogService, NbTagComponent, NbToastrService } from "@nebular/theme";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RoleGuard } from "../../guards/role-guard";

@Component({
  selector: "ngx-table-data[data][pageChangeEvent]",
  templateUrl: "./table-data.component.html",
  styleUrls: ["./table-data.component.scss"],
})
export class TableDataComponent implements OnInit, OnChanges {
  @Input() data: TableDto;
  @Input() tableId: string;
  @Input() showPagination = true;
  @Output() pageChangeEvent = new EventEmitter<any>();
  page: number;
  @Input() loadFilter: boolean;
  loading = true;
  expandedCategories: Set<number> = new Set();
  constructor(
    private exportsService: ExportsService,
    private dialogService: NbDialogService,
    private toastService: NbToastrService,
    private _route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private roleGuard: RoleGuard
  ) {}
  toggleCategory(categoryId: number): void {
    if (this.expandedCategories.has(categoryId)) {
      this.expandedCategories.delete(categoryId);
    } else {
      this.expandedCategories.add(categoryId);
    }
  }
  isCategoryExpanded(categoryId: number): boolean {
    return this.expandedCategories.has(categoryId);
  }
  ngOnInit() {
    this._route.queryParams.subscribe((params: Params) => {
      const pageKey = this.tableId || "page";

      if (params[pageKey] !== this.page) {
        this.page = Number(params[pageKey]) || 1;
      }

      if (params["tableId"] && params["tableId"] === this.tableId) {
        this.loading = true;
      }
    });
  }

  onTagRemove(tag: NbTagComponent, field) {
    field.action(tag.text);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loading = !!this.loadFilter;

    if (this.data && this.data.categories && this.data.categories.length > 0) {
      const firstCategoryId = this.data.categories[0].category_id;
      this.expandedCategories.add(firstCategoryId);
    }
  }

  pageChange(page: any) {
    if (this.page === page.page) {
      return;
    }

    this.page = page.page || 1;
    this.loading = true;
    this.pageChangeEvent.emit(this.page);
    const queryParams = this.tableId
      ? { ...this._route.snapshot.queryParams, [this.tableId]: this.page }
      : { page: this.page };
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams,
      queryParamsHandling: "merge",
      replaceUrl: true,
    });
  }

  openNewPage(event: MouseEvent, url, target) {
    this.cancelDefault(event);
    window.open(url, target);
  }
  cancelDefault(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  async export(type, $event?): Promise<void> {
    if (this.data.exportCategoryData || this.data.exportGameData) {
      this.data.filters = {
        ...this.data.filters,
        exportType: $event,
      };
    }
    try {
      await this.exportsService.create(type, this.data.filters);
      const successMessage = await this.translateService.get("export.export_success").toPromise();
      this.toastService.success(
        successMessage,
        this.translateService.instant("export.confirmation_title")
      );
    } catch (e) {
      const errorMessage = await this.translateService.get("export.export_fail").toPromise();
      this.toastService.danger(
        errorMessage,
        this.translateService.instant("export.confirmation_title")
      );
    }
  }

  buttonAction(event, action, i) {
    this.cancelDefault(event);
    action(i, event);
  }
}
