export class ObjectHelper {
  static removeEmpty(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "object") {
        this.removeEmpty(obj[key]);
      } else if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        delete obj[key];
      }
    });
    return obj;
  }
}
