import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FilterFieldDto } from "../../dto/filter-field.dto";
import { DateHelper } from "../../../../services/date";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { isReferenceEqual } from "@angular/compiler-cli/src/ngtsc/incremental/semantic_graph";

@Component({
  selector: "ngx-date-input[form][field]",
  templateUrl: "./date-input.component.html",
})
export class DateInputComponent implements OnChanges, OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() field: FilterFieldDto;

  showCustom = false;

  dateFieldSub: Subscription;
  customStartSub: Subscription;
  customEndSub: Subscription;

  constructor(private route: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["form"] && !changes["field"]) {
      this.ngOnInit();
    }
  }

  ngOnInit(): void {
    const queryParameters = this.route.snapshot.queryParams;
    if (queryParameters[this.field.key]) {
      this.handleQueryParamChange(queryParameters[this.field.key]);
    } else if (this.field.selectedValue) {
      this.dateChange(this.field.selectedValue);
    }

    this.dateFieldSub = this.form.get(this.field.key).valueChanges.subscribe((value) => {
      this.showCustom = value === "custom";
    });

    this.customStartSub = this.form
      .get(this.field.key + "_start_custom")
      .valueChanges.subscribe((value) => {
        if (!this.showCustom) return;
        this.dateChange("custom_start", value);
      });

    this.customEndSub = this.form
      .get(this.field.key + "_end_custom")
      .valueChanges.subscribe((value) => {
        if (!this.showCustom) return;
        this.dateChange("custom_end", value);
      });
  }

  ngOnDestroy(): void {
    this.dateFieldSub.unsubscribe();
    this.customStartSub.unsubscribe();
    this.customEndSub.unsubscribe();
  }

  handleQueryParamChange(dateValue: string) {
    if (dateValue === "custom") {
      this.showCustom = true;
    } else {
      this.dateChange(dateValue);
    }
  }

  dateChange(event, value?: string) {
    const startKey = this.field.key + "_start_value";
    const endKey = this.field.key + "_end_value";
    const today = DateHelper.getDaysAgo(0);

    this.showCustom = false;

    let newFormValues = {};

    switch (event) {
      case "none":
        newFormValues[startKey] = "";
        newFormValues[endKey] = "";
        break;
      case "today":
        newFormValues[startKey] = this.convertStartDate(today);
        newFormValues[endKey] = this.convertEndDate(today);
        break;
      case "yesterday":
        const yesterday = DateHelper.getDaysAgo(1);
        newFormValues[startKey] = this.convertStartDate(yesterday);
        newFormValues[endKey] = this.convertEndDate(yesterday);
        break;
      case "last_seven_days":
        const seven_days = DateHelper.getDaysAgo(6);
        newFormValues[startKey] = this.convertStartDate(seven_days);
        newFormValues[endKey] = this.convertEndDate(today);
        break;
      case "last_thirty_days":
        const thirty_days = DateHelper.getDaysAgo(30);
        newFormValues[startKey] = this.convertStartDate(thirty_days);
        newFormValues[endKey] = this.convertEndDate(today);
        break;
      case "this_month":
        const this_month = DateHelper.getStartOfTheMonth(0);
        newFormValues[startKey] = this.convertStartDate(this_month);
        newFormValues[endKey] = this.convertEndDate(today);
        break;
      case "last_month":
        const last_month_start = DateHelper.getStartOfTheMonth(1);
        const last_month_end = DateHelper.getStartOfTheMonth(0);
        newFormValues[startKey] = this.convertStartDate(last_month_start);
        newFormValues[endKey] = this.convertStartDate(last_month_end);
        break;
      case "custom":
        this.showCustom = true;
        break;
      case "custom_start":
        if (value) {
          const custom_date_start = DateHelper.getCustomDateTime(value);
          newFormValues[startKey] = this.convertDateCustom(custom_date_start);
        } else {
          newFormValues[startKey] = undefined;
        }

        this.showCustom = true;
        break;
      case "custom_end":
        if (value) {
          const custom_date_end = DateHelper.getCustomDateTime(value);
          newFormValues[endKey] = this.convertDateCustom(custom_date_end);
        } else {
          newFormValues[endKey] = undefined;
        }
        this.showCustom = true;
        break;
    }
    this.form.patchValue(newFormValues, { emitEvent: false });
  }

  private convertStartDate(date) {
    return DateHelper.convertToTimestamp(date, "start");
  }

  private convertEndDate(date) {
    return DateHelper.convertToTimestamp(date, "end");
  }

  private convertDateCustom(date: string) {
    return DateHelper.convertToTimestampCustom(date);
  }
}
