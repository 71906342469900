import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterDto } from "./dto/filter.dto";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { DateHelper } from "../../services/date";
import { ObjectHelper } from "../../pages/slots/helpers/object.helper";
import { FilterType } from "./dto/filter-field.dto";
import { ProvidersService } from "../../pages/providers/providers.service";
@Component({
  selector: "ngx-filter[filterData][submitCallback]",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit {
  @Input() filterData: FilterDto;
  @Input() filterId: string;
  @Input() activeTab;
  @Output() submitCallback = new EventEmitter<any>();
  form: FormGroup;
  types: any = {};

  readonly numberTypes = ["number", "operator", "provider", "games", "dropdown"];

  keysToExclude = ["provider_id", "operator_id", "game_id"];
  excludedStatus = {};

  playerSearchKey;
  filteredProviders;

  anyExcluded = false;

  constructor(
    private fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private readonly providerService: ProvidersService
  ) {}

  async ngOnInit(): Promise<void> {
    const queryParameters = this._route.snapshot.queryParams;
    this.activeTab = queryParameters.activeTab;
    for (const key in queryParameters) {
      if (this.keysToExclude.includes(key)) {
        this.excludedStatus[key] = false;
        this.anyExcluded = true;
      }
    }
    const pageValue = this.getPageValueFromQueryParams(queryParameters);
    setTimeout(() => {
      this.setFormValues(queryParameters);
      if (!this.anyExcluded) {
        this.submitCallback.emit({ ...this.form.value, page: pageValue });
      }
    }, 2);
    this.buildForm();
    await this.loadProvidersIfNeeded();
  }

  private async loadProvidersIfNeeded() {
    if (this.hasType(FilterType.OPERATOR) && !this.hasType(FilterType.PROVIDER)) {
      (await this.providerService.getProviders()).subscribe((res: any) => {
        this.filteredProviders = res;
      });
    }
  }

  private hasType(property: FilterType) {
    let hasType = false;
    this.filterData.columns.forEach((column) => {
      column.filters.forEach((field) => {
        if (field.type == property) {
          hasType = true;
          return;
        }
      });
      if (hasType) return;
    });

    return hasType;
  }

  private getPageValueFromQueryParams(queryParams): number {
    for (const key in queryParams) {
      if (key.includes("page")) {
        return +queryParams[key];
      }
    }
    return 1;
  }

  buildForm(): void {
    let config: { [p: string]: any } = {};
    for (let column of this.filterData.columns) {
      for (let filter of column.filters) {
        if (filter.type === "player") {
          config["player_id"] = [];
          this.types["player_id"] = "number";
          this.playerSearchKey = filter.key;
        } else if (filter.type === "date") {
          config[filter.key + "_start_value"] = "";
          config[filter.key + "_end_value"] = "";
          config[filter.key + "_start_custom"] = [];
          config[filter.key + "_end_custom"] = [];
        } else if (filter.type === "game_table") {
          config[filter.key + "_game"] = "all";
          config[filter.key + "_table"] = "";
          config["visible_id"] = "";
        }

        this.types[filter.key] = filter.type;
        config[filter.key] = [
          { value: filter.selectedValue ?? "", disabled: filter.disabled },
          filter.validations,
        ];
      }
    }

    this.form = this.fb.group(config);
  }

  setFormValues(params: Params) {
    for (const entry of Object.entries(params)) {
      const key = entry[0];
      const value = entry[1];

      if (this.keysToExclude.includes(key)) {
        continue;
      }

      let formKey = this.filterId ? key.replace(this.filterId + "_", "") : key;

      if (this.form.controls[formKey]) {
        let valueToSet =
          this.numberTypes.indexOf(this.types[formKey]) !== -1 && value && !isNaN(value)
            ? Number(value)
            : value;
        if (formKey.endsWith("_custom") && valueToSet) {
          valueToSet = DateHelper.convertToTimestampCustomDate(valueToSet);
        }
        this.form.controls[formKey].setValue(valueToSet);
      }
    }
  }

  loadKey(key: string) {
    this.excludedStatus[key] = true;

    for (const excludedKey in this.excludedStatus) {
      if (this.excludedStatus[excludedKey] == false) {
        return;
      }
    }

    this.setExcludedParams(this._route.snapshot.queryParams);
  }

  setExcludedParams(params: Params) {
    if (!this.anyExcluded) {
      return;
    }

    setTimeout(() => {
      for (const entry of Object.entries(params)) {
        const key = entry[0];
        const value = entry[1];

        if (this.form.controls[key]) {
          const valueToSet =
            this.numberTypes.indexOf(this.types[key]) !== -1 && value && !isNaN(value)
              ? Number(value)
              : value;
          this.form.controls[key].setValue(valueToSet);
        }
      }
      const pageValue = this.getPageValueFromQueryParams(this._route.snapshot.queryParams);

      this.submitCallback.emit({
        ...this.form.value,
        page: pageValue,
      });
    }, 1);
  }

  changeProvider(providers: any[]) {
    this.filteredProviders = providers;
  }

  clear() {
    this.buildForm();
    setTimeout(() => {
      this.submitCallback.emit(this.form.value);
    }, 1);
    this.setQueryParams();
  }

  submit() {
    ObjectHelper.removeEmpty(this.form.value);

    for (let column of this.filterData.columns) {
      for (let filter of column.filters) {
        if (filter.type === "date" && this.form.controls[filter.key].value === "custom") {
          const startKey = filter.key + "_start_value";
          const endKey = filter.key + "_end_value";
          const startCustomKey = filter.key + "_start_custom";
          const endCustomKey = filter.key + "_end_custom";

          this.form.controls[startKey].setValue(
            this.convertDateCustom(this.form.controls[startCustomKey].value)
          );

          this.form.controls[endKey].setValue(
            this.convertDateCustom(this.form.controls[endCustomKey].value)
          );
        }
      }
    }

    setTimeout(() => {
      let formValue = { ...this.form.value };

      if (this.filterId) {
        formValue = this.filterFormValue(formValue);
      }
      this.submitCallback.emit(formValue);
    }, 1);

    this.setQueryParams();
  }

  filterFormValue(formValue) {
    return Object.keys(formValue).reduce((newFormValue, key) => {
      const newKey = key.startsWith(this.filterId + "_")
        ? key.slice(this.filterId.length + 1)
        : key;
      newFormValue[newKey] = formValue[key];
      return newFormValue;
    }, {});
  }

  private convertDateCustom(date) {
    return DateHelper.convertToTimestampCustom(date);
  }

  onActiveTabChanged(tab: string) {
    this.activeTab = tab;
    this.setQueryParams();
  }

  async setQueryParams() {
    const params = ObjectHelper.removeEmpty(JSON.parse(JSON.stringify(this.form.value)));
    params.activeTab = this.activeTab;
    Object.keys(params).forEach((key) => {
      let value = params[key];
      if (value === null && this.types[key] === "dropdown") {
        value = [];
      }

      if (this.filterId) {
        key = this.filterId + "_" + key;
      }
      params[key] = value;
    });
    await this._router.navigate([], {
      relativeTo: this._route,
      queryParams: params,
      replaceUrl: true,
      // preserve the existing query params in the route
    });
  }
}
