import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { NbMenuItem, NbMenuService } from "@nebular/theme";
import { OperatorsService } from "../../pages/operators/operators.service";
import { ProvidersService } from "../../pages/providers/providers.service";

@Component({
  selector: "ngx-selection-menu[userRole]",
  templateUrl: "./selection-menu.component.html",
  styleUrls: ["./selection-menu.component.scss"],
})
export class SelectionMenuComponent implements OnInit, OnChanges {
  @Output()
  targetChange = new EventEmitter<{ query; name }>();
  @Input() userRole;
  @Input() amounts;

  providers;
  operators;
  selectedIndex = 0;
  items: NbMenuItem[] = [];

  constructor(
    public operatorService: OperatorsService,
    public providerService: ProvidersService,
    menu: NbMenuService
  ) {
    menu.onItemClick().subscribe((value) => {
      if (!value.item.data) return;
      this.targetChange.emit({ query: value.item.data, name: value.item.title });
      this.items[this.selectedIndex].selected = false;
      this.selectedIndex = value.item.data.index;
      value.item.selected = true;
    });
  }

  async ngOnInit() {
    await this.getProviders();
  }

  async ngOnChanges() {
    if (this.amounts) {
      this.setupMenu();
    }
  }

  async getProviders() {
    if (this.userRole === "operator") {
      await this.getOperators();
    } else {
      (await this.providerService.getProviders()).subscribe((data) => {
        this.providers = data;
        this.getOperators();
      });
    }
  }

  async getOperators() {
    (await this.operatorService.getOperators()).subscribe((data) => {
      this.operators = data;
      if (this.providers) {
        this.providers.forEach((provider) => {
          provider["operators"] = [];
          this.operators.forEach((operator) => {
            if (operator.provider_id === provider.id) {
              provider["operators"].push(operator);
            }
          });
        });
      }

      this.setupMenu();
    });
  }

  findAmount(operator_id, provider_id): number {
    if (this.amounts) {
      for (let amount of this.amounts) {
        if (amount.operator_id === operator_id && amount.provider_id === provider_id)
          return amount.amount;
      }
    }
    return 0;
  }

  getBadge(amount: number) {
    return amount
      ? {
          text: amount + "",
          status: "primary",
        }
      : {};
  }

  setupMenu() {
    this.items = [];
    if (this.userRole === "admin") {
      const amount = this.findAmount(null, null);
      this.items.push({
        title: "All",
        data: { index: 0 },
        selected: this.selectedIndex === 0,
        badge: this.getBadge(amount),
      });
      if (this.selectedIndex === 0) {
        this.targetChange.emit({ query: {}, name: "All" });
      }
    } else if (this.userRole === "operator") {
      if (!this.operators) return;

      const operator = this.operators[0];
      const amount = this.findAmount(operator.id, null);
      this.items.push({
        title: operator.name,
        data: { operator_id: operator.id, index: 0 },
        selected: this.selectedIndex === 0,
        badge: this.getBadge(amount),
      });
      if (this.selectedIndex === 0) {
        this.targetChange.emit({ query: { operator_id: operator.id }, name: operator.name });
      }
      return;
    }

    let i = 1;
    if (this.providers && this.providers[0]) {
      for (let provider of this.providers) {
        const amount = this.findAmount(null, provider.id);
        this.items.push({
          title: provider.name,
          data: { provider_id: provider.id, index: i },
          badge: this.getBadge(amount),
          selected: this.selectedIndex === i,
        });

        if (this.selectedIndex === i++) {
          this.targetChange.emit({ query: { provider_id: provider.id }, name: provider.name });
        }

        if (provider.operators) {
          this.items.push(
            ...provider.operators.map((operator) => {
              const amount = this.findAmount(operator.id, null);
              if (this.selectedIndex === i) {
                this.targetChange.emit({
                  query: { operator_id: operator.id },
                  name: operator.name,
                });
              }
              return {
                title: operator.name,
                data: { operator_id: operator.id, index: i },
                icon: "minus",
                selected: this.selectedIndex === i++,
                badge: this.getBadge(amount),
              };
            })
          );
        }
      }
    } else if (this.operators) {
      const amount = this.findAmount(this.operators[0].id, null);
      this.items.push({
        title: this.operators[0].name,
        data: { operator_id: this.operators[0].id, index: i },
        badge: this.getBadge(amount),
        selected: this.selectedIndex === i++,
      });
      this.targetChange.emit({
        query: { operator_id: this.operators[0].id },
        name: this.operators[0].name,
      });
    }
  }
}
