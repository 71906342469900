import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FilterFieldDto } from "../../dto/filter-field.dto";
import { OperatorsService } from "../../../../pages/operators/operators.service";
import { ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/filter";

@Component({
  selector: "ngx-select-operator[form][field][providers]",
  templateUrl: "./select-operator.component.html",
})
export class SelectOperatorComponent implements OnInit {
  constructor(private readonly operatorService: OperatorsService, private _route: ActivatedRoute) {}

  @Input() form: FormGroup;
  @Input() field: FilterFieldDto;
  @Input() bindValue?: string = "id";
  @Output() operatorLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() set providers(value: any[]) {
    this.filteredProviders = value;
    this.filterOperators();
  }

  filteredProviders;
  operators;

  async ngOnInit(): Promise<void> {
    if (!!this.field && !!this.field.bindValue) this.bindValue = this.field.bindValue;

    await this.getOperators();
  }

  async getOperators() {
    this.operators = await this.operatorService.getOperators().toPromise();
    this.filterOperators();

    this.operatorLoaded.emit(true);
  }

  async filterOperators() {
    if (!this.operators) {
      await this.getOperators();
    }
    if (this.filteredProviders) {
      this.filteredProviders.forEach((provider) => {
        provider["operators"] = [];
        this.operators.forEach((operator) => {
          if (operator.provider_id === provider.id) {
            provider["operators"].push(operator);
          }
        });
      });
    }
  }

  operatorSelected(e) {
    this.form.controls["player_name"]?.setValue("");
    this.form.controls["player_type"]?.setValue("");
    this.form.controls["player_id"]?.setValue("");
  }
}
