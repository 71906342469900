<div [formGroup]="form">
  <nb-select
    [id]="field.key"
    [formControlName]="field.key"
    fullWidth
    placeholder="{{ field.placeholder | translate }}"
    (selectedChange)="dateChange($event)">
    <nb-option *ngIf="!field.disableNone" [value]="'none'">{{ "lifetime" | translate }}</nb-option>
    <nb-option [value]="'today'">{{ "today" | translate }}</nb-option>
    <nb-option [value]="'yesterday'">{{ "yesterday" | translate }}</nb-option>
    <nb-option [value]="'last_seven_days'">{{ "last_seven_days" | translate }}</nb-option>
    <nb-option [value]="'last_thirty_days'">{{ "last_thirty_days" | translate }}</nb-option>
    <nb-option [value]="'this_month'">{{ "this_month" | translate }}</nb-option>
    <nb-option [value]="'last_month'">{{ "last_month" | translate }}</nb-option>
    <nb-option [value]="'custom'">{{ "custom" | translate }}</nb-option>
  </nb-select>

  <input
    *ngIf="showCustom"
    [id]="field.key + '_start_custom'"
    [formControlName]="field.key + '_start_custom'"
    nbInput
    fullWidth
    [autocomplete]="'off'"
    [readonly]="true"
    class="mt-1"
    placeholder="{{ 'start_date' | translate }}"
    [nbDatepicker]="date_start_custom" />
  <nb-date-timepicker #date_start_custom format="dd-MM-YYYY, HH:mm"></nb-date-timepicker>

  <input
    *ngIf="showCustom"
    [id]="field.key + '_end_custom'"
    [formControlName]="field.key + '_end_custom'"
    nbInput
    fullWidth
    [autocomplete]="'off'"
    [readonly]="true"
    class="mt-1"
    placeholder="{{ 'end_date' | translate }}"
    [nbDatepicker]="date_end_custom" />
  <nb-date-timepicker #date_end_custom format="dd-MM-YYYY, HH:mm"></nb-date-timepicker>
</div>
