import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TransactionStatus } from "../../dtos/enums/Transaction";

@Injectable({
  providedIn: "root",
})
export class TransactionsService {
  constructor(private http: HttpClient) {}
  getTransactions(filters: any, page: number) {
    return this.http.post("transactions/get_transactions" + (page ? "?page=" + page : ""), {
      filters: filters,
    });
  }
  getTransaction(id) {
    return this.http.get(`transactions/get_transaction/${id}`);
  }
  deletePendingTransactions(id) {
    return this.http.post(`transactions/pending_transactions/delete/${id}`, {});
  }
  public gameDetail(uuid: string, token: string): any {
    return this.http.get(`transactions/detail/${uuid}?token=${token}`);
  }
  retryTransaction(id) {
    return this.http.post<any>(`transactions/retry/${id}`, {}).toPromise();
  }
  updateTransactionStatus(id, status: TransactionStatus) {
    return this.http.put<any>(`transactions/${id}`, { status }).toPromise();
  }
  getPendingTransactionsCount(): Promise<number> {
    return this.http
      .get<number>(`transactions/count/pending`)
      .toPromise()
      .catch((_) => {
        return 0;
      });
  }
}
