import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { buildQueryParams } from "../../helpers/query.helper";
import { Category } from "../../models/Category";
import { GameFilter } from "../../models/Game";
import { PaginatedGames } from "../../models/PaginatedGames";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GamesService {
  constructor(private http: HttpClient) {}

  async getGames() {
    return this.http.get<GameFilter[]>("games/get_for_filter").toPromise();
  }

  async getAllGames(filters, page, pageSize: number = 50): Promise<Observable<PaginatedGames>> {
    return this.http.post<PaginatedGames>("games/get_all" + buildQueryParams({ page, pageSize }), {
      filters,
    });
  }

  async createGame(data) {
    return this.http.post(`games/create`, { data: data });
  }

  async getGame(id) {
    return this.http.get(`games/get_game/${id}`);
  }

  async updateGame(id, values) {
    return this.http.post(`games/update_game/${id}`, { values });
  }

  async delete_limit(id) {
    return this.http.post(`games/delete_limit/${id}`, {});
  }

  async getGameByCategories() {
    return this.http.get<Category[]>(`categories/`).toPromise();
  }
}
