import { Component, HostListener, Input, OnInit } from "@angular/core";
import { NbThemeService } from "@nebular/theme";
import { StackedChartDTO } from "./dto/stacked-chart.dto";

@Component({
  selector: "ngx-stacked-chart[data]",
  templateUrl: "./stacked-chart.component.html",
  styleUrls: ["./stacked-chart.component.scss"],
})
export class StackedChartComponent implements OnInit {
  @Input() data: StackedChartDTO;
  @Input() colorScheme?: any;
  @Input() yAxis?: boolean = true;
  @Input() xAxis?: boolean = true;
  @Input() legend?: boolean = true;
  @Input() legendPosition?: string = "right";
  @Input() customHeight: boolean = false;

  resolvedHeight: string;
  themeSubscription: any;

  constructor(private theme: NbThemeService) {
    if (this.colorScheme) {
      return;
    }

    this.themeSubscription = this.theme.getJsTheme().subscribe((config) => {
      const colors: any = config.variables;
      this.colorScheme = {
        domain: [
          colors.primaryLight,
          colors.infoLight,
          colors.successLight,
          colors.warningLight,
          colors.dangerLight,
        ],
      };
    });
  }
  ngOnInit() {
    this.resolveHeight();
  }

  @HostListener("window:resize")
  onResize() {
    this.resolveHeight();
  }

  resolveHeight() {
    if (this.customHeight) {
      this.resolvedHeight = window.innerWidth <= 768 ? "200px" : "300px";
    } else {
      this.resolvedHeight = "100%";
    }
  }
}
