import { NgModule } from "@angular/core";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { StackedChartComponent } from "./stacked-chart.component";

@NgModule({
  declarations: [StackedChartComponent],
  exports: [StackedChartComponent],
  imports: [NgxChartsModule],
})
export class StackedChartModule {}
