import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PlayersCreateRequest } from "../../dtos/PlayersCreateRequest";
import { PlayersCreateResponse } from "../../dtos/PlayersCreateResponse";

@Injectable({
  providedIn: "root",
})
export class PlayersService {
  constructor(private http: HttpClient) {}

  getPlayers(filters, page) {
    return this.http.post("players/get_players" + (page ? "?page=" + page : ""), { filters });
  }

  public getPlayer(id): any {
    return this.http.post(`players/get_player/${id}`, {});
  }

  public getPlayerReport(id, filters): any {
    return this.http.post(`players/get_player_report/${id}`, { filters });
  }

  public getPlayerTransactions(id, page, filters): any {
    return this.http.post(`transactions/player/${id}` + (page ? `?page=` + page : ``), { filters });
  }

  exportTable(filters) {
    return this.http.post("players/get_players", { filters });
  }

  getPlayersForFilter(filters) {
    return this.http.post("players/get_for_filter", { filters });
  }

  createPlayers(body: PlayersCreateRequest) {
    return this.http.post<PlayersCreateResponse>("players/create_multiple", body).toPromise();
  }

  changeBlacklistStatus(id, status) {
    return this.http.get(`players/blacklist/${id}/${status}`).toPromise();
  }

  setPlayerType(id, type) {
    return this.http.put(`players/type/${id}`, { type }).toPromise();
  }
}
