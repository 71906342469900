import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[ngxNumberInputSanitizer]",
})
export class NumberInputSanitizerDirective {
  constructor(private elementRef: ElementRef) {}
  @Input() supportsNegative: boolean = false;
  @HostListener("input") onInput() {
    const regex = this.supportsNegative ? /[^-0-9]/g : /[^0-9]/g;
    this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.replace(regex, "");
  }
}
