import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NumberInputSanitizerDirective } from "./number-input-sanitizer.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [NumberInputSanitizerDirective],
  exports: [CommonModule, NumberInputSanitizerDirective],
})
export class ComponentDirectivesModule {}
