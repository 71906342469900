import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { NbAuthService } from "@nebular/auth";
import { BehaviorSubject, Observable } from "rxjs";
import { NbMenuItem } from "@nebular/theme";
import { TranslateService } from "@ngx-translate/core";
import { TransactionsService } from "../pages/transactions/transactions.service";

@Injectable()
export class RoleGuard implements CanActivate {
  role: any;
  permissions: any = {};
  MENU_ITEMS: NbMenuItem[] = [];
  permissionsSubject = new BehaviorSubject({});

  constructor(
    private authService: NbAuthService,
    private router: Router,
    private translateService: TranslateService,
    public transactionsService: TransactionsService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkUserLogin(next);
  }

  createMenuItems() {
    this.MENU_ITEMS = [
      {
        title: "menu.dashboard",
        icon: "home-outline",
        link: "/pages/dashboard",
        hidden: !this.permissions.dashboard?.read,
      },
      {
        title: "menu.reports",
        icon: "trending-up-outline",
        pathMatch: "full",
        hidden: !this.permissions.reports?.read,
        children: [
          {
            title: "menu.general_reports",
            link: "/pages/reports",
            pathMatch: "full",
            hidden: !this.permissions.reports?.general?.read,
          },
          {
            title: "menu.provider_reports",
            link: "/pages/reports/provider",
            pathMatch: "full",
            hidden: !this.permissions.reports?.provider?.read,
          },
          {
            title: "menu.operator_reports",
            link: "/pages/reports/operator",
            pathMatch: "full",
            hidden: !this.permissions.reports?.operator?.read,
          },
          {
            title: "menu.game_reports",
            link: "/pages/reports/game",
            pathMatch: "full",
            hidden: !this.permissions.reports?.game?.read,
          },
          {
            title: "menu.player_reports",
            link: "/pages/reports/player",
            pathMatch: "full",
            hidden: !this.permissions.reports?.player?.read,
          },
          {
            title: "menu.registration_reports",
            link: "/pages/reports/registration",
            pathMatch: "full",
            hidden: !this.permissions.reports?.registration?.read,
          },
          {
            title: "menu.commission_reports",
            link: "/pages/reports/commission",
            pathMatch: "full",
            hidden: !this.permissions.reports?.commission?.read,
          },
        ],
      },
      {
        title: "menu.exports",
        icon: "download-outline",
        hidden: !this.permissions.exports?.read,
        link: "/pages/exports/list",
        pathMatch: "full",
      },
      {
        title: "menu.transactions",
        icon: "flip-outline",
        hidden: !this.permissions.transactions?.read,
        children: [
          {
            title: "menu.all_transactions",
            link: "/pages/transactions",
            pathMatch: "full",
            hidden: !this.permissions.transactions?.read,
          },
          {
            title: "menu.pending_transactions",
            link: "/pages/transactions/pending",
            pathMatch: "full",
            hidden: !this.permissions.pending_transactions?.read,
          },
        ],
      },
      {
        title: "menu.users_groups_header",
        hidden:
          !this.permissions.players?.read &&
          !this.permissions.test_players?.read &&
          !this.permissions.users?.read &&
          !this.permissions.providers?.read &&
          !this.permissions.operators?.read,
        group: true,
      },
      {
        title: "menu.players",
        icon: "person-outline",
        link: "/pages/players",
        hidden: !this.permissions.players?.read,
      },
      {
        title: "menu.bots",
        icon: "smiling-face",
        hidden: !this.permissions.bots?.read,
        children: [
          {
            title: "menu.bots_dashboard",
            link: "/pages/bots/dashboard",
            pathMatch: "full",
            hidden: !this.permissions.bots?.dashboard?.read,
          },
          {
            title: "menu.bots_transactions",
            link: "/pages/bots/transactions",
            hidden: !this.permissions.bots?.transactions?.read,
          },
        ],
      },
      {
        title: "menu.test_players",
        icon: "person",
        hidden: !this.permissions.test_players?.read,
        children: [
          {
            title: "menu.test_players_dashboard",
            link: "/pages/test-players/dashboard",
            pathMatch: "full",
            hidden: !this.permissions.test_players?.read,
          },
          {
            title: "menu.test_players_list",
            link: "/pages/test-players/list",
            hidden: !this.permissions.test_players?.read,
          },
        ],
      },
      {
        title: "menu.users",
        icon: "people-outline",
        hidden: !this.permissions.users?.read,
        children: [
          {
            title: "menu.all_users",
            link: "/pages/users",
            pathMatch: "full",
            hidden: !this.permissions.users?.read,
          },
          {
            title: "menu.create_user",
            link: "/pages/users/create",
            hidden: !this.permissions.users?.manage,
          },
        ],
      },
      {
        title: "menu.providers",
        icon: "cloud-download-outline",
        hidden: !this.permissions.providers?.read,
        children: [
          {
            title: "menu.all_providers",
            link: "/pages/providers",
            pathMatch: "full",
            hidden: !this.permissions.providers?.read,
          },
          {
            title: "menu.create_provider",
            link: "/pages/providers/create",
            pathMatch: "full",
            hidden: !this.permissions.providers?.manage,
          },
        ],
      },
      {
        title: "menu.operators",
        icon: "browser-outline",
        pathMatch: "full",
        hidden: !this.permissions.operators?.read,
        children: [
          {
            title: "menu.all_operators",
            link: "/pages/operators",
            pathMatch: "full",
            hidden: !this.permissions.operators?.read,
          },
          {
            title: "menu.create_operator",
            link: "/pages/operators/create",
            pathMatch: "full",
            hidden: !this.permissions.operators?.manage,
          },
        ],
      },
      {
        title: "menu.settings_header",
        hidden:
          !this.permissions.cms?.read &&
          !this.permissions.notifications?.read &&
          !this.permissions.limits?.read,
        group: true,
      },
      {
        title: "menu.cms_translations",
        icon: "message-square-outline",
        link: "/pages/cms/translations",
        pathMatch: "full",
        hidden: !this.permissions.cms?.translations?.read,
      },
      {
        title: "menu.notifications",
        icon: "bell-outline",
        hidden: !this.permissions.notifications?.read,
        children: [
          {
            title: "menu.notifications_all",
            link: "/pages/notifications",
            pathMatch: "full",
            hidden: !this.permissions.notifications?.read,
          },
          {
            title: "menu.notifications_settings",
            link: "/pages/notifications/settings",
            pathMatch: "full",
            hidden: !this.permissions.notifications?.settings?.read,
          },
        ],
      },
      {
        title: "menu.limits",
        icon: "lock-outline",
        hidden: !this.permissions.limits?.read,
        link: "/pages/limits",
        pathMatch: "full",
      },
      {
        title: "menu.rake",
        icon: "percent-outline",
        hidden: !this.permissions.commissions?.read,
        link: "/pages/rake",
        pathMatch: "full",
      },
      {
        title: "menu.telegram_bots_header",
        link: "/pages/bots/telegram",
        hidden: !this.permissions.bots?.telegram?.manage,
        icon: "paper-plane-outline",
      },
      {
        title: "menu.games_header",
        hidden:
          !this.permissions.games?.read &&
          !this.permissions.games?.raptor?.read &&
          !this.permissions.games?.shell?.read &&
          !this.permissions.games?.tombala?.read &&
          !this.permissions.games?.card?.read,
        group: true,
      },
      {
        title: "menu.games",
        icon: "grid-outline",
        pathMatch: "full",
        hidden: !this.permissions.games?.read,
        children: [
          {
            title: "menu.all_games",
            link: "/pages/games",
            pathMatch: "full",
            hidden: !this.permissions.games?.read,
          },
          {
            title: "menu.create_game",
            link: "/pages/games/manage",
            pathMatch: "full",
            hidden: !this.permissions.games?.manage,
          },
        ],
      },
      {
        title: "menu.categories",
        icon: { icon: "categories", pack: "icon" },
        pathMatch: "full",
        hidden: !this.permissions.games?.read,
        link: "/pages/categories",
      },
      {
        title: "menu.raptor",
        icon: { icon: "raptor", pack: "icon" },
        hidden: !this.permissions.games?.raptor?.read,
        children: [
          {
            title: "menu.game_history",
            link: "/pages/raptor/history",
            pathMatch: "full",
          },
        ],
      },
      {
        title: "menu.shell",
        icon: { icon: "shell", pack: "icon" },
        hidden: !this.permissions.games?.shell?.read,
        children: [
          {
            title: "menu.game_history",
            link: "/pages/shell/history",
            pathMatch: "full",
          },
        ],
      },
      {
        title: "menu.slots",
        icon: { icon: "slots", pack: "icon" },
        hidden: !this.permissions.games?.slots?.read && !this.permissions.operators.manage,
        children: [
          {
            hidden: !this.permissions.games?.slots?.read,
            title: "menu.game_history",
            link: "/pages/slots/history",
            pathMatch: "full",
          },
          {
            hidden: !this.permissions.operators.manage,
            title: "menu.promotions",
            link: "/pages/slots/promotions",
            pathMatch: "full",
          },
          {
            hidden: !this.permissions.games?.slots?.read,
            title: "menu.promotions_history",
            link: "/pages/slots/promotions/history",
            pathMatch: "full",
          },
        ],
      },
      {
        title: "menu.hilo",
        icon: { icon: "hilo", pack: "icon" },
        hidden: !this.permissions.games?.hilo?.read,
        children: [
          {
            title: "menu.game_history",
            link: "/pages/hilo/history",
            pathMatch: "full",
          },
        ],
      },
      {
        title: "menu.tombala",
        icon: "keypad-outline",
        hidden: !this.permissions.games?.tombala?.read,
        children: [
          {
            title: "menu.game_history",
            link: "/pages/tombala/history",
            pathMatch: "full",
          },
        ],
      },
      {
        title: "menu.kahve",
        icon: { icon: "kahve", pack: "icon" },
        hidden: !this.permissions.games?.card?.read,
        children: [
          {
            title: "menu.pisti",
            link: "/pages/kahve/pisti",
            pathMatch: "full",
          },
          {
            title: "menu.batak",
            link: "/pages/kahve/batak",
            pathMatch: "full",
          },
          {
            title: "menu.pis7",
            link: "/pages/kahve/pis7",
            pathMatch: "full",
          },
        ],
      },
    ];
    if (this.role === "admin" && this.permissions.transactions?.read) {
      this.transactionsService.getPendingTransactionsCount().then((count) => {
        if (count > 0) {
          const item = this.MENU_ITEMS.find((item) => item.icon === "flip-outline");
          if (item) {
            item.badge = { text: count.toString(), status: "danger" };
            const pendingItem = item.children.find(
              (value) => value.link === "/pages/transactions/pending"
            );
            if (pendingItem) {
              pendingItem.badge = { text: count.toString(), status: "danger" };
            }
          }
        }
      });
    }
    for (const each of this.MENU_ITEMS) {
      this.translateService.stream(each.title).subscribe((res) => {
        each.title = res;
      });

      this.translateChildren(each.children);
    }
    this.permissionsSubject.next(this.permissions);
  }

  translateChildren(children) {
    if (!children) return;
    for (const child of children) {
      this.translateService.stream(child.title).subscribe((res) => {
        child.title = res;
      });
      this.translateChildren(child.children);
    }
  }

  hasPermission(permission) {
    if (!permission) return true;
    if (Array.isArray(permission)) {
      return permission.some((p) => this.hasPermission(p));
    }
    const permArray = permission.split(".");
    let hasPermission = this.permissions;

    for (const perm of permArray) {
      if (!hasPermission) return false;
      hasPermission = hasPermission[perm];
    }
    return hasPermission;
  }

  async checkUserLogin(route: ActivatedRouteSnapshot) {
    if (this.authService.isAuthenticated()) {
      if (Object.keys(this.permissions).length === 0) {
        const credentials = JSON.parse(localStorage.getItem("credentials") || "{}");
        this.permissions = credentials.perms;
        this.permissionsSubject.next(this.permissions);
        this.role = credentials.role;

        if (this.permissions) this.createMenuItems();
      }

      if (this.hasPermission(route.data.permission)) return true;
    }

    this.router.navigate(["/pages/home"]);
    return false;
  }
}
