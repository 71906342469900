<div class="col-lg-12">
  <nb-card>
    <nb-accordion>
      <nb-accordion-item [collapsed]="false">
        <nb-accordion-item-header>{{ "create" | translate }}</nb-accordion-item-header>
        <nb-accordion-item-body>
          <form [formGroup]="form">
            <div class="row">
              <div class="col-md-6" *ngFor="let column of createData.columns">
                <ng-container *ngFor="let items of column.fields">
                  <div class="form-group" *ngIf="!items.hidden">
                    <label [for]="items.key" class="label" [class.label-none]="!items.label">{{
                      items.label | translate
                    }}</label>
                    <nb-select
                      *ngIf="items.type === 'dropdown'"
                      [id]="items.key"
                      [formControlName]="items.key"
                      fullWidth
                      [placeholder]="items.placeholder | translate"
                      [selected]="items.selectedValue">
                      <nb-option *ngFor="let value of items.values" [value]="value.key">
                        {{ value.name | translate }}
                      </nb-option>
                    </nb-select>

                    <textarea
                      *ngIf="items.type === 'text'"
                      [id]="items.key"
                      [formControlName]="items.key"
                      nbInput
                      type="text"
                      autocomplete="off"
                      fullWidth
                      [placeholder]="items.placeholder | translate"
                      rows="5"></textarea>
                    <ngx-select-provider
                      *ngIf="items.type === 'provider'"
                      [form]="form"
                      [field]="items"
                      (changeProvider)="changeProvider($event)">
                    </ngx-select-provider>

                    <ngx-select-operator
                      *ngIf="items.type === 'operator'"
                      [form]="form"
                      [field]="items"
                      [providers]="filteredProviders">
                    </ngx-select-operator>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="col-md-4 d-flex align-items-center ml-auto mt-2">
              <button
                *ngFor="let b of createData.buttons"
                type="button"
                class="mx-1"
                nbButton
                fullWidth
                [status]="b.status"
                (click)="b.onClick === 'cancel' ? cancel() : submit()"
                [disabled]="b.onClick === 'cancel' ? false : !form.valid">
                {{ b.label | translate }}
              </button>
            </div>
          </form>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </nb-card>
</div>
