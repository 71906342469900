import { Validators } from "@angular/forms";

export interface FilterFieldDto {
  label?: string;
  id?: any;
  key: any;
  placeholder?: string;
  selectedValue?: any;
  type:
    | "date"
    | "text"
    | "number"
    | "dropdown"
    | "operator"
    | "provider"
    | "player"
    | "game_table"
    | "role"
    | "games";
  supportsNegative?: boolean;
  disabled?: boolean;
  bindValue?: string;
  hidden?: boolean;
  values?: { key: string | number; name: string }[];
  validations?: Validators[];
  dateType?: "start" | "end";
  disableNone?: boolean;
  errorValidation?: any;
  isTransaction?: boolean;
}

// TODO change it above and everywhere where used
export enum FilterType {
  DATE = "date",
  TEXT = "text",
  NUMBER = "number",
  DROPDOW = "dropdown",
  OPERATOR = "operator",
  PROVIDER = "provider",
  PLAYER = "player",
  GAME_TABLE = "game_table",
  ROLE = "role",
  GAMES = "games",
}
