export enum GameKeys {
  TOMBALA = "falcon_tombala",
  KAHVE = "falcon_kahve",
  SLOTS = "falcon_slots",
}

export const tables = {
  [GameKeys.TOMBALA]: [
    { id: "auto_tombala", name: "Auto Tombala" },
    { id: "live_tombala", name: "Live Tombala" },
    { id: "yummy_tombala", name: "Yummy Tombala" },
  ],
  [GameKeys.KAHVE]: [
    { id: "pisti", name: "All Pişti" },
    { id: "pisti_21", name: "- Pişti 21" },
    { id: "pisti_51", name: "- Pişti 51" },
    { id: "batak", name: "All Batak" },
    { id: "batak_ihaleli", name: "- Batak İhaleli" },
    { id: "batak_kozmaca", name: "- Batak Koz Maça" },
    { id: "batak_gommeli", name: "- Batak Gommeli" },
    { id: "pis7", name: "All Pis Yedili" },
    { id: "pis7_klasik", name: "- Pis Yedili Klasik" },
    { id: "pis7_jokerli", name: "- Pis Yedili Jokerli" },
  ],
  [GameKeys.SLOTS]: [
    { id: "sweet_fruits", name: "Sweet Fruits" },
    { id: "ottoman", name: "Ottoman" },
    { id: "onwin", name: "Onwin" },
    { id: "scarecrow", name: "Scarecrow" },
  ],
};
