import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FilterFieldDto } from "../../dto/filter-field.dto";
import { ProvidersService } from "../../../../pages/providers/providers.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "ngx-select-provider[form][field][changeProvider]",
  templateUrl: "./select-provider.component.html",
})
export class SelectProviderComponent implements OnInit {
  constructor(private readonly providerService: ProvidersService, private _route: ActivatedRoute) {}

  @Input() form: FormGroup;
  @Input() field: FilterFieldDto;
  @Output() changeProvider: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() providerLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  userRole = JSON.parse(localStorage.getItem("credentials")).role;

  providers: any = [];

  async ngOnInit(): Promise<void> {
    await this.getProviders();
  }

  async getProviders() {
    (await this.providerService.getProviders()).subscribe((data) => {
      this.providers = data;
      this.changeProvider.emit(this.providers);

      this.providerLoaded.emit(true);
    });
  }

  providerSelected($event) {
    this.changeProvider.emit(
      $event ? this.providers.filter((provider) => provider.id === $event) : this.providers
    );
    this.form.controls["operator_id"]?.setValue("");
    this.form.controls["player_name"]?.setValue("");
    this.form.controls["player_id"]?.setValue("");
  }
}
