import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FilterFieldDto } from "../../dto/filter-field.dto";
import { RolesService } from "../../../../pages/roles/roles.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "ngx-select-role[form][field]",
  templateUrl: "./select-role.component.html",
})
export class SelectRoleComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() field: FilterFieldDto;

  roleNames;

  constructor(private roleService: RolesService, private route: ActivatedRoute) {}

  async ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;

    if (queryParams[this.field.key]) {
      this.form.controls[this.field.key].setValue(queryParams[this.field.key]);
    }

    await this.getRoles();
  }

  async getRoles() {
    (await this.roleService.getRoles()).subscribe((data) => {
      this.roleNames = Object.keys(data);
    });
  }
}
