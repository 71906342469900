<div class="col-lg-12" *ngIf="!filterData.compare && !filterData.dateTabs">
  <nb-card>
    <nb-accordion>
      <nb-accordion-item [collapsed]="false">
        <nb-accordion-item-header>{{ "filter" | translate }}</nb-accordion-item-header>
        <nb-accordion-item-body>
          <form class="row" [formGroup]="form">
            <div
              [ngClass]="filterData.isTopPlayer ? 'col-md-6' : 'col-md-4'"
              *ngFor="let column of filterData.columns">
              <ng-container *ngFor="let filter of column.filters">
                <div class="form-group" *ngIf="!filter.hidden">
                  <label [for]="filter.key" class="label" [class.label-none]="!filter.label">{{
                    filter.label | translate
                  }}</label>
                  <nb-select
                    *ngIf="filter.type === 'dropdown'"
                    [id]="filter.key"
                    [formControlName]="filter.key"
                    fullWidth
                    [placeholder]="filter.placeholder | translate"
                    [selected]="filter.selectedValue">
                    <nb-option *ngFor="let value of filter.values" [value]="value.key">
                      {{ value.name | translate }}
                    </nb-option>
                  </nb-select>

                  <ngx-player-search
                    *ngIf="filter.type === 'player'"
                    [form]="form"
                    [playerSearchKey]="playerSearchKey"
                    [field]="filter">
                  </ngx-player-search>

                  <input
                    *ngIf="filter.type === 'text'"
                    [id]="filter.key"
                    [formControlName]="filter.key"
                    nbInput
                    (keyup.enter)="submit()"
                    type="text"
                    autocomplete="off"
                    fullWidth
                    [placeholder]="filter.placeholder | translate" />

                  <input
                    *ngIf="filter.type === 'number'"
                    [id]="filter.key"
                    ngxNumberInputSanitizer
                    [supportsNegative]="filter?.supportsNegative"
                    [formControlName]="filter.key"
                    nbInput
                    (keyup.enter)="submit()"
                    type="number"
                    autocomplete="off"
                    fullWidth
                    [placeholder]="filter.placeholder | translate" />
                  <div *ngIf="filter.errorValidation">
                    <small class="text-danger">
                      {{ filter.errorValidation }}
                    </small>
                  </div>
                  <ngx-select-provider
                    *ngIf="filter.type === 'provider'"
                    [form]="form"
                    [field]="filter"
                    (providerLoaded)="loadKey(filter.key)"
                    (changeProvider)="changeProvider($event)">
                  </ngx-select-provider>

                  <ngx-select-operator
                    *ngIf="filter.type === 'operator'"
                    [form]="form"
                    [field]="filter"
                    [providers]="filteredProviders"
                    (operatorLoaded)="loadKey(filter.key)">
                  </ngx-select-operator>

                  <ngx-select-role *ngIf="filter.type === 'role'" [form]="form" [field]="filter">
                  </ngx-select-role>

                  <ngx-select-game
                    *ngIf="filter.type === 'games'"
                    [form]="form"
                    [field]="filter"
                    (gameLoaded)="loadKey(filter.key)">
                  </ngx-select-game>

                  <ngx-date-input *ngIf="filter.type === 'date'" [form]="form" [field]="filter">
                  </ngx-date-input>

                  <ngx-select-game-table
                    *ngIf="filter.type === 'game_table'"
                    [form]="form"
                    [field]="filter"></ngx-select-game-table>
                </div>
              </ng-container>
            </div>
            <div
              class="d-flex align-items-center ml-auto mt-2 pt-4"
              [ngClass]="{ 'col-md-4': !filterData.isTopPlayer }">
              <button
                *ngFor="let b of filterData.buttons"
                type="button"
                class="mx-1"
                nbButton
                fullWidth
                [status]="b.status"
                (click)="b.onClick === 'clear' ? clear() : submit()"
                [disabled]="b.disabled"
                [nbSpinner]="b.disabled">
                {{ b.label | translate }}
              </button>
            </div>
          </form>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </nb-card>
</div>

<div *ngIf="filterData.compare">
  <div class="row">
    <form id="form-compare" class="form_compare" [formGroup]="form">
      <ng-container
        *ngTemplateOutlet="
          compareTemplate;
          context: { $implicit: filterData.columns[0].filters }
        "></ng-container>

      <div class="col-xl-2 column">
        <nb-card class="compare-card p-3 pb-4">
          <nb-card-body>
            <ng-container *ngFor="let button of filterData.buttons">
              <h3>{{ button.buttonLabel | translate }}</h3>
              <button
                *ngFor="let b of filterData.buttons"
                type="button"
                nbButton
                fullWidth
                [status]="b.status"
                (click)="b.onClick === 'clear' ? clear() : submit()"
                [disabled]="b.disabled"
                [nbSpinner]="b.disabled">
                {{ b.label | translate }}
              </button>
            </ng-container>
          </nb-card-body>
        </nb-card>
      </div>

      <ng-container
        *ngTemplateOutlet="
          compareTemplate;
          context: { $implicit: filterData.columns[1].filters }
        "></ng-container>
    </form>
  </div>
  <ng-template #compareTemplate let-filters>
    <div class="col-xl-5 column">
      <nb-card class="compare-card p-3">
        <nb-card-body>
          <ng-container *ngFor="let filter of filters">
            <div class="form-group w-100 mb-0" *ngIf="!filter.hidden && filter.type === 'date'">
              <label [for]="filter.key" class="label" [class.label-none]="!filter.label">
                {{ filter.label | translate }}
              </label>
              <ngx-date-input [form]="form" [field]="filter"></ngx-date-input>
            </div>
          </ng-container>
        </nb-card-body>
      </nb-card>
    </div>
  </ng-template>
</div>
<div *ngIf="filterData.dateTabs">
  <ngx-dashboard-tabs
    [form]="form"
    [filterData]="filterData"
    [filteredProviders]="filteredProviders"
    (loadKeyEvent)="loadKey($event)"
    (changeProviderEvent)="changeProvider($event)"
    (clearEvent)="clear()"
    (submitEvent)="submit()"
    (activeTabChanged)="onActiveTabChanged($event)">
  </ngx-dashboard-tabs>
</div>
