import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleGuard } from '../../../guards/role-guard';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{key: 'log_out', title: 'Log out', link: '/auth/logout' }];
  languages = [
    {key: 'tr', name: 'Türkçe'},
    {key: 'en', name: 'English'},
  ];
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserService,
              private layoutService: LayoutService,
              public translate: TranslateService,
              public roleGuard: RoleGuard,              
              private breakpointService: NbMediaBreakpointsService) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName); 

      this.roleGuard.permissionsSubject.subscribe((data) => {
        const optionExists = this.userMenu.find((el) => el.key == 'password_change')
        const hasPermission = this.roleGuard.hasPermission('password.manage'); 
        
        if (hasPermission && !optionExists) {
          this.userMenu.unshift({ key: 'password_change', title: "Change password", link: '/pages/change-password' });        
        } else if (!hasPermission && optionExists) {
          this.userMenu = this.userMenu.filter((el) => el.key != 'password_change')
        }
      })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  useLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('language', language);
    this.roleGuard.createMenuItems();
  }
}
