<div class="col-lg-12" *ngIf="data">
  <nb-card>
    <nb-card-header style="{{ data.withoutTableHeaders ? 'display: none' : '' }}">
      <div class="d-flex align-items-center justify-content-between">
        <span>{{ data.label | translate }}</span>
        <div>
          <button
            *ngIf="data.exportType && roleGuard.permissions?.exports.manage"
            nbButton
            status="info"
            size="small"
            [disabled]="data.totalItems === 0"
            (click)="export(data.exportType)"
            translate>
            export_table
          </button>
          <button
            *ngIf="data.exportGameData && roleGuard.permissions?.exports.manage"
            nbButton
            status="info"
            size="small"
            [disabled]="data.totalItems === 0"
            (click)="export(data.exportGameData, 'data')"
            translate>
            export_game_data
          </button>
          <button
            *ngIf="data.exportCategoryData && roleGuard.permissions?.exports.manage"
            nbButton
            status="info"
            size="small"
            [disabled]="data.totalItems === 0"
            (click)="export(data.exportGameData, 'category')"
            style="margin-left: 20px"
            translate>
            export_category_data
          </button>
          <button
            *ngIf="data.header_button_action"
            nbButton
            status="info"
            size="small"
            style="margin-left: 20px"
            (click)="data.header_button_action()"
            translate>
            {{ data.header_button_text }}
          </button>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="table-responsive">
        <table class="table table-hover col-lg-12" id="transactions-table">
          <thead>
            <tr>
              <th scope="col" *ngFor="let header of data.headers">
                {{ header | translate }}
              </th>
            </tr>
          </thead>
          <tbody
            *ngIf="data.rows?.length || data.categories?.length"
            [nbSpinner]="loading"
            nbSpinnerStatus="danger">
            <ng-container *ngIf="data.categories">
              <ng-container *ngFor="let category of data.categories">
                <ng-container *ngIf="category.sum_row; else onlyRowsTemplate">
                  <ng-container *ngFor="let row of category.sum_row">
                    <tr
                      *ngIf="!row.link"
                      (click)="toggleCategory(category.category_id)"
                      [ngClass]="{ 'category-border': isCategoryExpanded(category.category_id) }">
                      <div class="arrow-container">
                        <svg
                          class="arrow-icon"
                          viewBox="0 0 24 24"
                          [class.expanded]="isCategoryExpanded(category.category_id)">
                          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z"></path>
                        </svg>
                      </div>
                      <ng-container
                        *ngTemplateOutlet="tRow; context: { $implicit: row }"></ng-container>
                      <span></span>
                    </tr>
                  </ng-container>

                  <ng-container *ngIf="isCategoryExpanded(category.category_id)">
                    <ng-container *ngFor="let row of category.rows; let i = index; let last = last">
                      <a
                        *ngIf="row.link"
                        [routerLink]="row.link"
                        [ngClass]="{ 'row-border': true, 'last-row-border': last }">
                        <ng-container
                          *ngTemplateOutlet="
                            tRow;
                            context: { $implicit: row, index: i }
                          "></ng-container>
                      </a>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-template #onlyRowsTemplate>
                  <ng-container *ngFor="let row of category.rows; let i = index">
                    <a *ngIf="row.link" routerLink="{{ row.link }}">
                      <ng-container
                        *ngTemplateOutlet="
                          tRow;
                          context: { $implicit: row, index: i }
                        "></ng-container>
                    </a>
                  </ng-container>
                </ng-template>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!data.categories">
              <ng-container *ngFor="let row of data.rows; let i = index">
                <a *ngIf="row.link" routerLink="{{ row.link }}">
                  <ng-container
                    *ngTemplateOutlet="tRow; context: { $implicit: row, index: i }"></ng-container>
                </a>
                <tr *ngIf="!row.link">
                  <ng-container
                    *ngTemplateOutlet="tRow; context: { $implicit: row, index: i }"></ng-container>
                </tr>
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let row of data.extraRows; let i = index">
              <a *ngIf="row.link" routerLink="{{ row.link }}">
                <ng-container
                  *ngTemplateOutlet="tRow; context: { $implicit: row, index: i }"></ng-container>
              </a>
              <tr *ngIf="!row.link" class="extra-rows">
                <ng-container
                  *ngTemplateOutlet="tRow; context: { $implicit: row, index: i }"></ng-container>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <nb-alert
        accent="danger"
        *ngIf="!data.rows && !data.categories"
        [nbSpinner]="true"
        nbSpinnerStatus="danger">
        {{ "loading_table" | translate }}
      </nb-alert>
      <nb-alert accent="danger" class="text-center" *ngIf="data.rows && !data.rows.length">
        {{ "no_data" | translate }}
      </nb-alert>
      <nb-alert
        accent="danger"
        class="text-center"
        *ngIf="data.categories && !data.categories.length">
        {{ "no_data" | translate }}
      </nb-alert>

      <ng-template #tRow let-row let-index="index">
        <td *ngFor="let field of row.fields" [ngClass]="row.alignActions ? 'align-actions' : ''">
          <ng-container *ngIf="!field.hidden">
            <ng-container *ngIf="field.img">
              <div [ngClass]="field.customClass || ''">
                <img [src]="field.img.src" [alt]="field.img.alt" />
              </div>
            </ng-container>
            <ng-container *ngIf="!field.actionType && !field.img && field.link && !field.linkName">
              <a class="text-link" [routerLink]="field.link">
                {{ field.translate ? (field | fieldPipe | translate) : (field | fieldPipe) }}
              </a>
              <a (click)="openNewPage($event, field.link, field.target || '_blank')">
                <nb-icon icon="external-link-outline"></nb-icon>
              </a>
            </ng-container>
            <button
              [ngClass]="field.customClass || ''"
              *ngIf="field.actionType && !field.switch"
              nbButton
              [size]="field.size || 'medium'"
              (click)="field.action ? buttonAction($event, field.action, index) : undefined"
              [routerLink]="field.link || undefined"
              [status]="field.actionType"
              [disabled]="field.disabled">
              {{ field.value | translate }}
            </button>
            <ng-container *ngIf="field.switch">
              <div class="d-flex flex-column align-items-center">
                <div class="custom-toggle">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="field.status"
                      (change)="buttonAction($event, field.action, index)"
                      [disabled]="field.disabled" />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="field.reward">
              <ng-container *ngFor="let item of field.reward">
                <div [ngClass]="item.wrapperClass || ''">
                  <span [ngClass]="item.customClass || ''">{{ item.value }}</span>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="field.list && !field.isTag">
              <ng-container *ngFor="let item of field.list">
                <ul class="no-bullets">
                  <li>{{ item }}</li>
                </ul>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="field.list && field.isTag">
              <nb-tag-list (tagRemove)="onTagRemove($event, field)">
                <nb-tag
                  *ngFor="let item of field.list"
                  status="basic"
                  appearance="outline"
                  [text]="item"
                  style="margin-right: 10px; margin-bottom: 10px"
                  removable></nb-tag>
              </nb-tag-list>
            </ng-container>
            <ng-container *ngIf="field.group">
              <ng-container *ngFor="let item of field.group">
                <!-- todo -->
                <button
                  [ngClass]="item.customClass || ''"
                  *ngIf="item.actionType && !item.switch"
                  class="m-1"
                  nbButton
                  [size]="item.size || 'medium'"
                  (click)="item.action ? buttonAction($event, item.action, index) : undefined"
                  [routerLink]="item.link || undefined"
                  [status]="item.actionType"
                  [disabled]="item.disabled">
                  {{ item.value | translate }}
                </button>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!field.actionType && !field.img && !field.link && !field.action">
              <div [ngClass]="field.customClass || ''">
                {{ field.translate ? (field | fieldPipe | translate) : (field | fieldPipe) }}
              </div>
            </ng-container>
          </ng-container>
        </td>
        <a *ngIf="row.link" (click)="openNewPage($event, row.link, row.target || '_blank')">
          <nb-icon icon="external-link-outline"></nb-icon>
        </a>
      </ng-template>
      <pagination
        *ngIf="showPagination && data.totalItems"
        previousText="{{ 'previous' | translate }}"
        nextText="{{ 'next' | translate }}"
        firstText="{{ 'first' | translate }}"
        lastText="{{ 'last' | translate }}"
        [(ngModel)]="page"
        [maxSize]="7"
        [boundaryLinks]="true"
        (pageChanged)="pageChange($event)"
        [itemsPerPage]="data.itemsPerPage"
        [totalItems]="data.totalItems"></pagination>
    </nb-card-body>
  </nb-card>
</div>
<div class="col-lg-12" *ngIf="!data" [nbSpinner]="true" nbSpinnerStatus="danger">
  <nb-card>
    <nb-card-body translate> loading_table</nb-card-body>
  </nb-card>
</div>
